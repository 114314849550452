 
function Politicas() {
  return (
    <>
       <div class="container-fluid">
                <div class="row mb-3">
                    <div class="col" style={{ marginTop: 70 + "px" }}>
                        <section class="team-boxed" style={{ background: " rgba(238,244,247,0)" }}>
                            <div class="container">
                                <div style={{ marginTop: 20 + "px" }}>
                                    <div class="card shadow mb-4">
                                        <div class="card-body">
                                        <h1>Políticas de Privacidad</h1>
    <p><strong>Última actualización: 12 de julio de 2024</strong></p>
    <p>Esta Política de privacidad describe nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de su información cuando utiliza el Servicio y le informa sobre sus derechos de privacidad y cómo la ley lo protege.</p>
    <p>Usamos sus datos personales para proporcionar y mejorar el Servicio. Al usar el Servicio, acepta la recopilación y el uso de información de acuerdo con esta Política de privacidad.</p>

    <h2>Interpretación y Definiciones</h2>
    <h3>Interpretación</h3>
    <p>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural.</p>

    <h3>Definiciones</h3>
    <p>A los efectos de esta Política de privacidad:</p>
    <ul>
        <li><strong>Cuenta</strong>: Una cuenta única creada para que usted acceda a nuestro Servicio o partes de nuestro Servicio.</li>
        <li><strong>Afiliado</strong>: Una entidad que controla, es controlada o está bajo el control común de una parte, donde "control" significa la propiedad del 50 % o más de las acciones, participación accionaria u otros valores con derecho a voto para la elección de directores u otra autoridad administrativa.</li>
        <li><strong>Aplicación</strong>: El programa de software proporcionado por la <strong>Compañía</strong> descargado por usted en cualquier dispositivo electrónico, llamado Soli360.</li>
        <li><strong>Compañía</strong> (referida como "la Compañía", "nosotros", "nos" o "nuestro" en este Acuerdo): <strong>EQUIPO GERENCIAL CON SOLIDEZ GCS, C.A.</strong>, CALLE PARÍS CON NUEVA YORK EDIF THEMIS MARY PISO 1 DE 3 URB LAS MERCEDES BARUTA, Venezuela.</li>
        <li><strong>País</strong>: Venezuela.</li>
        <li><strong>Dispositivo</strong>: Cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.</li>
        <li><strong>Datos personales</strong>: Cualquier información relacionada con una persona física identificada o identificable.</li>
        <li><strong>Servicio</strong>: La Aplicación.</li>
        <li><strong>Proveedor de servicios</strong>: Cualquier persona física o jurídica que procesa los datos en nombre de la <strong>Compañía</strong>. Se refiere a empresas de terceros o personas empleadas por la <strong>Compañía</strong> para facilitar el Servicio, proporcionar el Servicio en nombre de la <strong>Compañía</strong>, realizar servicios relacionados con el Servicio o ayudar a la <strong>Compañía</strong> a analizar cómo se utiliza el Servicio.</li>
        <li><strong>Datos de uso</strong>: Datos recopilados automáticamente, ya sea generados por el uso del Servicio o por la propia infraestructura del Servicio (por ejemplo, la duración de una visita a la página).</li>
        <li><strong>Usted</strong>: La persona que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda.</li>
    </ul>

    <h2>Recopilación y uso de sus datos personales</h2>
    <h3>Tipos de datos recopilados</h3>
    <h4>Datos personales</h4>
    <p>Mientras usa nuestro Servicio, podemos pedirle que nos proporcione cierta información de identificación personal que se puede usar para contactarlo o identificarlo. La información de identificación personal puede incluir, entre otros:</p>
    <ul>
        <li>Dirección de correo electrónico</li>
        <li>Nombre y apellido</li>
        <li>Datos de uso</li>
    </ul>

    <h4>Datos de uso</h4>
    <p>Los datos de uso se recopilan automáticamente cuando se utiliza el Servicio.</p>
    <p>Los datos de uso pueden incluir información como la dirección del protocolo de Internet de su dispositivo (por ejemplo, la dirección IP), el tipo de navegador, la versión del navegador, las páginas de nuestro Servicio que visita, la hora y la fecha de su visita, el tiempo que pasa en esas páginas, identificadores únicos de dispositivos y otros datos de diagnóstico.</p>
    <p>Cuando accede al Servicio a través de un dispositivo móvil, podemos recopilar cierta información automáticamente, incluido, entre otros, el tipo de dispositivo móvil que utiliza, la identificación única de su dispositivo móvil, la dirección IP de su dispositivo móvil, su sistema operativo móvil, el tipo de navegador de Internet móvil que utiliza, identificadores únicos de dispositivos y otros datos de diagnóstico.</p>
    <p>También podemos recopilar información que su navegador envía cada vez que visita nuestro Servicio o cuando accede al Servicio a través de un dispositivo móvil.</p>

    <h3>Uso de sus datos personales</h3>
    <p>La <strong>Compañía</strong> podrá utilizar los Datos Personales para los siguientes fines:</p>
    <ul>
        <li><strong>Para proporcionar y mantener nuestro Servicio</strong>, incluido el control del uso de nuestro Servicio.</li>
        <li><strong>Para administrar su Cuenta</strong>: para administrar su registro como usuario del Servicio. Los Datos Personales que proporcione pueden darle acceso a diferentes funcionalidades del Servicio que están disponibles para usted como usuario registrado.</li>
        <li><strong>Para la ejecución de un contrato</strong>: el desarrollo, cumplimiento y realización del contrato de compra de los productos, artículos o servicios que haya adquirido o de cualquier otro contrato con nosotros a través del Servicio.</li>
        <li><strong>Para contactarlo</strong>: para contactarlo por correo electrónico, llamadas telefónicas, SMS u otras formas equivalentes de comunicación electrónica, como notificaciones automáticas de una aplicación móvil con respecto a actualizaciones o comunicaciones informativas relacionadas con las funcionalidades, productos o servicios contratados, incluidas las actualizaciones de seguridad, cuando sea necesario o razonable para su implementación.</li>
        <li><strong>Para proporcionarle noticias, ofertas especiales e información general sobre otros bienes, servicios y eventos que ofrecemos</strong> que son similares a los que ya ha comprado o sobre los que ha consultado, a menos que haya optado por no recibir dicha información.</li>
        <li><strong>Para gestionar sus solicitudes</strong>: Para atender y gestionar sus solicitudes hacia nosotros.</li>
        <li><strong>Para transferencias comerciales</strong>: podemos usar su información para evaluar o realizar una fusión, desinversión, reestructuración, reorganización, disolución u otra venta o transferencia de algunos o todos nuestros activos, ya sea como negocio en marcha o como parte de una quiebra, liquidación o procedimiento similar, en el que los datos personales que tenemos sobre los usuarios de nuestro Servicio se encuentran entre los activos transferidos.</li>
        <li><strong>Para otros fines</strong>: podemos utilizar su información para otros fines, como el análisis de datos, la identificación de tendencias de uso, la determinación de la eficacia de nuestras campañas promocionales y la evaluación y mejora de nuestro Servicio, productos, servicios, marketing y su experiencia.</li>
    </ul>

    <h4>Podemos compartir su información personal en las siguientes situaciones:</h4>
    <ul>
        <li><strong>Con proveedores de servicios</strong>: Podemos compartir su información personal con proveedores de servicios para monitorear y analizar el uso de nuestro Servicio, para contactarlo.</li>
        <li><strong>Para transferencias comerciales</strong>: podemos compartir o transferir su información personal en relación con, o durante las negociaciones de, cualquier fusión, venta de activos de la <strong>Compañía</strong>, financiamiento o adquisición de la totalidad o una parte de nuestro negocio a otra empresa.</li>
        <li><strong>Con afiliados</strong>: podemos compartir su información con nuestros afiliados, en cuyo caso les exigiremos que respeten esta Política de privacidad. Los afiliados incluyen nuestra empresa matriz y cualquier otra subsidiaria, socios de empresas conjuntas u otras empresas que controlamos o que están bajo control común con nosotros.</li>
        <li><strong>Con socios comerciales</strong>: podemos compartir su información con nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.</li>
        <li><strong>Con otros usuarios</strong>: cuando comparte información personal o interactúa en las áreas públicas con otros usuarios, dicha información puede ser vista por todos los usuarios y puede distribuirse públicamente al exterior.</li>
        <li><strong>Con su consentimiento</strong>: podemos divulgar su información personal para cualquier otro propósito con su consentimiento.</li>
    </ul>

    <h3>Retención de sus datos personales</h3>
    <p>La <strong>Compañía</strong> conservará sus datos personales solo durante el tiempo que sea necesario para los fines establecidos en esta Política de privacidad. Conservaremos y utilizaremos sus datos personales en la medida necesaria para cumplir con nuestras obligaciones legales (por ejemplo, si estamos obligados a conservar sus datos para cumplir con las leyes aplicables), resolver disputas y hacer cumplir nuestras políticas y acuerdos legales.</p>
    <p>La <strong>Compañía</strong> también conservará los datos de uso para fines de análisis interno. Los datos de uso generalmente se retienen por un período de tiempo más corto, excepto cuando estos datos se usan para fortalecer la seguridad o mejorar la funcionalidad de nuestro Servicio, o cuando estamos legalmente obligados a retener estos datos por períodos de tiempo más largos.</p>

    <h3>Transferencia de sus datos personales</h3>
    <p>Su información, incluidos los datos personales, se procesa en las oficinas operativas de la <strong>Compañía</strong> y en cualquier otro lugar donde se encuentren las partes involucradas en el procesamiento. Esto significa que esta información puede transferirse y mantenerse en computadoras ubicadas fuera de su estado, provincia, país u otra jurisdicción gubernamental donde las leyes de protección de datos pueden diferir de las de su jurisdicción.</p>
    <p>Su consentimiento a esta Política de privacidad seguido de su envío de dicha información representa su acuerdo con esa transferencia.</p>
    <p>La <strong>Compañía</strong> tomará todas las medidas razonablemente necesarias para garantizar que sus datos se traten de forma segura y de conformidad con esta Política de privacidad, y no se realizará ninguna transferencia de sus datos personales a una organización o país, a menos que existan controles adecuados establecidos, incluidos la seguridad de sus datos y otra información personal.</p>

    <h3>Eliminar sus datos personales</h3>
    <p>Tiene derecho a eliminar o solicitar que lo ayudemos a eliminar los datos personales que hemos recopilado sobre usted.</p>
    <p>Nuestro Servicio puede darle la posibilidad de eliminar cierta información sobre usted dentro del Servicio.</p>
    <p>Puede actualizar, modificar o eliminar su información en cualquier momento iniciando sesión en su cuenta, si tiene una, y visitando la sección de configuración de la cuenta que le permite administrar su información personal. También puede contactarnos para solicitar acceso, corregir o eliminar cualquier información personal que nos haya proporcionado.</p>
    <p>Sin embargo, tenga en cuenta que es posible que necesitemos retener cierta información cuando tengamos una obligación legal o una base legal para hacerlo.</p>

    <h3>Divulgación de sus datos personales</h3>
    <h4>Transacciones comerciales</h4>
    <p>Si la <strong>Compañía</strong> está involucrada en una fusión, adquisición o venta de activos, sus datos personales pueden transferirse. Le enviaremos un aviso antes de que sus datos personales se transfieran y queden sujetos a una Política de privacidad diferente.</p>

    <h4>Cumplimiento de la ley</h4>
    <p>En determinadas circunstancias, es posible que se le solicite a la <strong>Compañía</strong> que divulgue sus datos personales si así lo exige la ley o en respuesta a solicitudes válidas de las autoridades públicas (p. ej., un tribunal o una agencia gubernamental).</p>

    <h4>Otros requisitos legales</h4>
    <p>La <strong>Compañía</strong> puede divulgar sus datos personales de buena fe cuando considere que esta acción es necesaria para lo siguiente:</p>
    <ul>
        <li>Cumplir con una obligación legal</li>
        <li>Proteger y defender los derechos o la propiedad de la <strong>Compañía</strong></li>
        <li>Prevenir o investigar posibles irregularidades en relación con el Servicio</li>
        <li>Proteger la seguridad personal de los Usuarios del Servicio o del público</li>
        <li>Proteger contra responsabilidad legal</li>
    </ul>

    <h3>Seguridad de sus datos personales</h3>
    <p>La seguridad de sus datos personales es importante para nosotros, pero recuerde que ningún método de transmisión por Internet o método de almacenamiento electrónico es 100 % seguro. Si bien nos esforzamos por utilizar medios comercialmente aceptables para proteger sus datos personales, no podemos garantizar su seguridad absoluta.</p>

    <h3>Privacidad infantil</h3>
    <p>Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No recopilamos a sabiendas información de identificación personal de ninguna persona menor de 13 años. Si es padre o tutor y sabe que su hijo nos ha proporcionado datos, por favor contáctenos. Si nos damos cuenta de que hemos recopilado datos personales de cualquier persona menor de 13 años sin verificación del consentimiento de los padres, tomamos medidas para eliminar esa información de nuestros servidores.</p>
    <p>Si necesitamos confiar en el consentimiento como base legal para procesar su información y su país requiere el consentimiento de uno de sus padres, es posible que necesitemos el consentimiento de su padre antes de recopilar y usar esa información.</p>

    <h3>Enlaces a otros sitios web</h3>
    <p>Nuestro Servicio puede contener enlaces a otros sitios web que no operamos nosotros. Si hace clic en el enlace de un tercero, será dirigido al sitio de ese tercero. Le recomendamos encarecidamente que revise la Política de privacidad de cada sitio que visite.</p>
    <p>No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios o servicios de terceros.</p>

    <h3>Cambios a esta Política de Privacidad</h3>
    <p>Podemos actualizar nuestra Política de privacidad de vez en cuando. Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página.</p>
    <p>Le informaremos por correo electrónico y/o mediante un aviso destacado en nuestro Servicio, antes de que el cambio entre en vigencia y actualizaremos la "Última actualización" fecha en la parte superior de esta Política de privacidad.</p>
    <p>Le recomendamos que revise esta Política de privacidad periódicamente para ver si ha habido cambios. Los cambios a esta Política de privacidad son efectivos cuando se publican en esta página.</p>

    <h2>Rastreo de Ubicación</h2>
    <h3>Aceptación del rastreo de ubicación</h3>
    <p>El rastreo de ubicación solo se aplicará al perfil de vendedores que se entregará a los vendedores de los clientes de Consolidez, y se llevará a cabo únicamente mientras la aplicación esté en uso. Este rastreo permite a la aplicación proporcionar las siguientes funciones:</p>
    <ul>
        <li><strong>Registro de la ubicación de trabajo</strong>: La aplicación puede registrar la ubicación de trabajo del usuario, lo que permite a la empresa realizar un seguimiento de las horas de trabajo y la asistencia.</li>
        <li><strong>Optimización de rutas</strong>: La aplicación puede utilizar la ubicación del usuario para optimizar las rutas de viaje, lo que puede ayudar a ahorrar tiempo y combustible.</li>
        <li><strong>Monitoreo de la seguridad</strong>: La aplicación puede utilizar la ubicación del usuario para monitorear su seguridad, lo que puede ayudar a la empresa a responder rápidamente a emergencias.</li>
    </ul>

    <h3>Control del usuario sobre el rastreo de ubicación</h3>
    <p>El usuario, con perfil de vendedor, puede decidir cuándo compartir su ubicación. Puede activar o desactivar el rastreo de ubicación en cualquier momento a través de la configuración de la aplicación. Sin embargo, desactivar el rastreo de ubicación puede limitar la funcionalidad de la aplicación.</p>

    <p><strong>Importante</strong>: Al utilizar la aplicación, el usuario acepta los términos de esta Política de privacidad. Si el usuario no está de acuerdo con los términos de esta Política de privacidad, no debe utilizar la aplicación.</p>

    <h2>Contáctenos</h2>
    <p>Si tiene alguna pregunta sobre esta Política de privacidad, puede contactarnos:</p>
    <p>Por correo electrónico: <a href="mailto:soporte@consolidez.com.ve">soporte@consolidez.com.ve</a></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
    </>
  );
}

export default Politicas;
