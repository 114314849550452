import Soportes from "./Soporte";

function Home() {
  return (
    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col" style={{ marginTop: 70 + "px" }}>
          <section
            class="team-boxed"
            style={{ background: " rgba(238,244,247,0)" }}
          >
            <div class="container">
              <div style={{ marginTop: 20 + "px" }}>
                <div class="card shadow mb-4">
                  <div class="card-body">
                    <div class="row d-flex justify-content-center align-items-lg-center">
                      <div
                        class="col-md-5 col-lg-5 col-xl-4 col-xxl-4 d-flex justify-content-center"
                        style={{ padding: 5 + "px" }}
                      >
                        <img
                          src={__dirname + "Cuenta.jpg"}
                          style={{ width: 100 + "%" }}
                          alt=""
                        />
                      </div>
                      <div
                        class="col-md-5 col-lg-5 col-xl-4 col-xxl-4 d-flex justify-content-center"
                        style={{ padding: 5 + "px" }}
                      >
                        <img
                          src={__dirname + "Pagos.jpg"}
                          style={{ width: 100 + "%" }}
                          alt=""
                        />
                      </div>
                      <div
                        class="col-md-5 col-lg-5 col-xl-4 col-xxl-4 d-flex justify-content-center"
                        style={{ padding: 5 + "px" }}
                      >
                        <img
                          src={__dirname + "Reportes.jpg"}
                          style={{ width: 100 + "%" }}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Soportes />
          </section>
        </div>
      </div>
    </div>
  );
}

export default Home;
