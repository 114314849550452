import { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "../App.css";
import { WEB_API_SERVER } from "../const";
function EliminarCuenta() {
  // const { token } = useParams();
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("key");

  const deleteUsuario = async (vtoken) => {
    try {
      var requestOptions = {
        method: "DELETE",
        headers: {
          Authorization: process.env.REACT_APP_HeaderAuth,
          "Access-Control-Allow-Private-Network":
            process.env.REACT_APP_HeaderAccess,
          Cors: process.env.REACT_APP_HeaderCors,
        },
        redirect: "follow",
      };

      fetch(
        `${WEB_API_SERVER}api/Tecnico/DeleteUsuarioApp?Token=${token}`,
        requestOptions
      )
        .then((response) => response.json())
        .catch((error) => console.log("error", error));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    deleteUsuario(token);
    //console.log(token);
  }, []);

  return (
    <Container fluid className="px-4 pt-5" style={{ marginTop: 150 + "px" }}>
      <Row className="d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-sm-center justify-content-md-center justify-content-lg-center justify-content-xl-center">
        <Col className="col-xl-4">
          <center>
            <img src={__dirname + "logo_soli_360.png"} width="250" alt='' />
            <br />
            <br />

            <div className="card shadow mb-3">
              <div
                className="card-body"
                styles={{ BoxShadow: 0, fontSize: 14 + "px" }}
              >
                <div style={{ margin: 10 + "px" }}>
                  <p className="vtable">
                    Ha confirmado la eliminación de su cuenta.
                    <br />
                    <br />
                    En <b>Consolidez</b> estamos muy agradecidos de toda la
                    confianza que ha depositado en nosotros
                    <br />
                    <br />
                    Para nosotros ha sido una excelente experiencia, seguiremos
                    trabajando para un mejor futuro
                  </p>
                </div>
              </div>
            </div>
            <p className="vp">
              &copy; <strong>www.consolidez.com</strong>
            </p>
          </center>
        </Col>
      </Row>
    </Container>
  );
}

export default EliminarCuenta;
