import axios from "axios";
import { useState } from "react";
import Swal from "sweetalert2";
import { WEB_API_SERVER } from "../const";

function Soportes() {
  const [Correo, setCorreo] = useState("");
  const [Empresa, setEmpresa] = useState("");

  const PostCorreo = async () => {
    var rPostExtracto = await axios
      .post(`${WEB_API_SERVER}api/Mail/PostFormatoSoporte`, null, {
        params: {
          Email: Correo,
          Cliente: Empresa,
        },
        headers: {
          Authorization: process.env.REACT_APP_HeaderAuth,
          "Access-Control-Allow-Private-Network":
            process.env.REACT_APP_HeaderAccess,
          Cors: process.env.REACT_APP_HeaderCors,
        },
      })
      .then((result) => {});

    return rPostExtracto;
  };

  const CorreoEnviar = async () => {
    if (Correo.length === 0) {
      Swal.fire("Debe llenar el campo Correo", "", "error");

      return false;
    }
    if (Empresa.length === 0) {
      Swal.fire("Debe llenar el campo Empresa", "", "error");

      return false;
    }
    PostCorreo();
    window.location.reload(false);
  };
  return (
    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col">
          <section
            class="team-boxed"
            style={{ background: " rgba(238,244,247,0)" }}
          >
            <section class="position-relative py-4 py-xl-5">
              <div class="container position-relative">
                <div class="row d-flex justify-content-center">
                  <div class="col">
                    <div class="card shadow-lg o-hidden border-0 my-5">
                      <div class="card-body p-0">
                        <div class="row">
                          <div class="col-lg-5 d-none d-lg-flex justify-content-xl-center">
                            <img
                              width="100%"
                              src={__dirname + "soporte.jpg"}
                              style={{
                                width: 100 + "%",
                                maxHeight: 600 + "px",
                                maxWidth: 600 + "px",
                                padding: 40 + "px",
                              }}
                              alt=""
                            />
                          </div>
                          <div class="col-lg-7">
                            <div class="p-5">
                              <div class="text-center">
                                <h4 class="text-dark mb-4">Soporte</h4>
                              </div>

                              <div class="mb-3">
                                <input
                                  id="input-nombre"
                                  class="form-control"
                                  type="text"
                                  placeholder="Nombre"
                                />
                              </div>
                              <div class="mb-3">
                                <input
                                  id="input-correo"
                                  class="form-control"
                                  type="email"
                                  placeholder="Correo"
                                  onChange={(e) => setCorreo(e.target.value)}
                                />
                              </div>
                              <div class="mb-3">
                                <input
                                  id="input-empresa"
                                  class="form-control"
                                  type="email"
                                  placeholder="Empresa"
                                  onChange={(e) => setEmpresa(e.target.value)}
                                />
                              </div>
                              <div class="mb-3">
                                <textarea
                                  id="input-mensaje"
                                  class="form-control"
                                  placeholder="Mensaje"
                                  rows="7"
                                ></textarea>
                              </div>
                              <button
                                class="btn btn-primary d-block w-100"
                                onClick={CorreoEnviar}
                              >
                                Enviar
                              </button>

                              <div class="text-center"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Soportes;
