import MenuNav from "./component/MenuNav";
import Home from "./component/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Politicas from "./component/Politicas";
import Soportes from "./component/Soporte";
import EliminarCuenta from "./component/EliminarCuenta";
function App() {
  return (
    <BrowserRouter>
      <MenuNav />
      <div className="d-flex flex-column">
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="/politica-privacidad/v2" element={<Politicas />} />
          <Route path="/soporte/" element={<Soportes />} />
          <Route path="/eliminar-cuenta/" element={<EliminarCuenta />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
