 import { Link } from "react-router-dom";

function MenuNav() {
  return (
    <>
      <nav className="navbar navbar-light navbar-expand fixed-top bg-white shadow topbar static-top">
        <div className="container-fluid">
          <div style={{ margin: 10 + "px" }}>
            <img
              src={__dirname + "logo_soli_360.png"}
              width="140"
              height="31"
              alt=""
            />
          </div>
        </div>

        <Link class="nav-link" to="/">
          Home
        </Link>
        <Link class="nav-link" to="/soporte/">
          Soporte
        </Link>
        <Link class="nav-link" to="/politica-privacidad/v2">
          Políticas de Privacidad
        </Link>
      </nav>
    </>
  );
}

export default MenuNav;
